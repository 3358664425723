
import React, { useState } from "react";
import {
    Card,
    Input,
    Select,
    Typography,
    Option,
    Alert,
} from "@material-tailwind/react";
import { StepperData } from 'src/models/stepper';
import { Stepper } from 'src/components/stepper/stepper';
import { CreateOrder } from "src/models/requests";
import { createOrder, createOrderByAdmin, getAssets } from "src/api/order";
import { immediateToast } from "izitoast-react";
import { useNavigate } from "react-router-dom";
import { routes } from "src/data/routes";
import { useLocalStorage } from "src/hooks/storage.hook";
import { CryptoData } from "src/models/order";
import { useUser } from "src/hooks/user.hook";
import { Role } from "src/models/user";
import { validateEmail } from "src/utils";

export default function Order() {
    const [order, setOrder] = useState<CreateOrder>({
        name: '',
        phoneNumber: '',
        address: '',
        bankName: '',
        bankNumber: '',
        crypto: '',
        companyName: '',
        cryptoAddress: '',
        cryptoAmount: 0,
        cryptoNetwork: '',
    })
    const [isLoading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [tokens, setTokens] = useState<CryptoData[] | null>(null);
    const navigate = useNavigate();
    const { getObject, setObject } = useLocalStorage();
    const { user } = useUser();

    let assets: { networks: CryptoData[], tokens: CryptoData[] };
    assets = getObject('assets');
    if (!assets) {
        getAssets().then((result) => {
            setObject("assets", result.data);
            assets = result.data;
        });

    }


    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setOrder({ ...order, [name]: value });
    };


    const handleSelectCrypto = (network: any) => {
        const corsTokens = assets?.tokens.filter((elt) => elt.type.includes(network));
        setTokens(corsTokens);
        setOrder({ ...order, crypto: network });
    };

    const handleSelectCryptoNetwork = (token: any) => {
        setOrder({ ...order, cryptoNetwork: token });
    };


    const saveOrder = async () => {
        try {
            setLoading(true);
            const saveOrderFunc = user?.role === Role.ADMIN ? createOrderByAdmin : createOrder;
            await saveOrderFunc(order);
            immediateToast("success", {
                message: 'Order created successfully',
                position: "topRight"
            });
            navigate(routes.ORDER_HISTORY);

        } catch (exception: any) {
            const message = exception?.response?.data?.message || 'Something went wrong. Please try again';
            immediateToast("error", {
                message,
                position: "topRight"
            });
            setError(message);
        } finally {
            setLoading(false)
        }
    };

    const stepperData: StepperData[] = [{
        title: "Personal Informations",
        // active: true,
        active: (user?.role === Role.CLIENT && !!order.name && !!order.phoneNumber && !!order.address && !!order.companyName) || (user?.role === Role.ADMIN && !!order.name && !!order.phoneNumber && !!order.address && !!order.companyName && !!order.email && validateEmail(order?.email)),
        children: < Card color="transparent" shadow={false} className='w-full' >
            <form>
                <div className="mb-1 flex flex-col gap-3">
                    <Typography variant="h6" color="blue-gray">
                        Name
                    </Typography>
                    <Input
                        size="lg"
                        value={order.name}
                        name="name"
                        onChange={handleChange}
                        placeholder="John Doe"
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }} crossOrigin={undefined} />


                    <Typography variant="h6" color="blue-gray">
                        Phone number
                    </Typography>
                    <Input
                        size="lg"
                        placeholder="+237698949789"
                        value={order.phoneNumber}
                        name="phoneNumber"
                        onChange={handleChange}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }} crossOrigin={undefined} />
                    <Typography variant="h6" color="blue-gray">
                        Address
                    </Typography>
                    <Input
                        size="lg"
                        placeholder="Bonanjo Douala Cameroun"
                        value={order.address}
                        name="address"
                        onChange={handleChange}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }} crossOrigin={undefined} />
                    {user?.role === Role.ADMIN && <>
                        <Typography variant="h6" color="blue-gray">
                            Customer email
                        </Typography>
                        <Input
                            size="lg"
                            placeholder="john@example.com"
                            type="email"
                            value={order.email}
                            name="email"
                            color={validateEmail(order.email || '') ? 'green' : 'red'}
                            onChange={handleChange}
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }} crossOrigin={undefined} /></>}
                    <Typography variant="h6" color="blue-gray">
                        Company
                    </Typography>
                    <Input
                        size="lg"
                        placeholder="Ejara"
                        value={order.companyName}
                        name="companyName"
                        onChange={handleChange}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }} crossOrigin={undefined} />
                </div>
            </form>
        </Card >
    },
    {
        title: "Bank Informations",
        active: (!!order.bankName && !!order.bankNumber),
        // active: true,
        children: <Card color="transparent" shadow={false} className='w-full'>
            <form>
                <div className="mb-1 flex flex-col gap-6">
                    <Typography variant="h6" color="blue-gray">
                        Bank name
                    </Typography>
                    <Input
                        size="lg"
                        placeholder="Ex: Ecobank"
                        value={order.bankName}
                        name="bankName"
                        onChange={handleChange}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }} crossOrigin={undefined} />
                    <Typography variant="h6" color="blue-gray">
                        Bank number (IBAN)
                    </Typography>
                    <Input
                        size="lg"
                        placeholder="Ex: 0930093093"
                        value={order.bankNumber}
                        name="bankNumber"
                        onChange={handleChange}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }} crossOrigin={undefined} />
                </div>
            </form>
        </Card >
    },
    {
        title: "Amount Informations",
        active: (!!order.cryptoNetwork && !!order.crypto && !!order.cryptoAmount && !!order.cryptoAddress),
        // active: true,
        handler: saveOrder,
        isLoading,
        children: <Card color="transparent" shadow={false} className='w-full'>
            <form>
                <div className="mb-1 flex flex-col gap-6">
                    <Typography variant="h6" color="blue-gray">
                        Crypto
                    </Typography>
                    <Select variant="outlined" label="Select Crypto" name="crypto" selected={(element) =>
                        element &&
                        React.cloneElement(element, {
                            disabled: true,
                            className:
                                "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                        })
                    } value={order.crypto} onChange={handleSelectCrypto}>
                        {assets?.networks.map(({ icon, name, code }) => <Option className="bg-green flex items-center gap-2" key={code} value={code}>
                            <img
                                src={icon}
                                alt={name}
                                className="h-5 w-5 rounded-full object-cover"
                            />{code}</Option>)}
                    </Select>
                    <Typography variant="h6" color="blue-gray">
                        Crypto Network
                    </Typography>
                    <Select variant="outlined" label="Select Crypto Network" name="cryptoNetwork" selected={(element) =>
                        element &&
                        React.cloneElement(element, {
                            disabled: true,
                            className:
                                "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                        })
                    } onChange={handleSelectCryptoNetwork} disabled={!tokens}>
                        {tokens ? tokens.map(({ icon, name, code }) => <Option className="bg-green flex items-center gap-2" key={code} value={code}>
                            <img
                                src={icon}
                                alt={name}
                                className="h-5 w-5 rounded-full object-cover"
                            />{code}</Option>) : ''}
                    </Select>
                    <Typography variant="h6" color="blue-gray">
                        Crypto Amount
                    </Typography>
                    <Input
                        size="lg"
                        type="number"
                        placeholder="Ex: 500000"
                        value={order.cryptoAmount}
                        name="cryptoAmount"
                        onChange={handleChange}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }} crossOrigin={undefined} />

                    <Typography variant="h6" color="blue-gray">
                        Crypto Address
                    </Typography>
                    <Input
                        size="lg"
                        placeholder="TKAgLGVVwtwJnTmSHU6BQQQVzRHxZetvfo"
                        value={order.cryptoAddress}
                        name="cryptoAddress"
                        onChange={handleChange}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }} crossOrigin={undefined} />
                </div>
            </form>
            {error &&
                <Alert variant="ghost" color="red" className="mt-2">
                    <span>{error}.</span>
                </Alert>}

        </Card>
    }]
    return (
        <Stepper data={stepperData} />
    );
}