import { InvoiceListResponse, InvoiceResponse } from "src/models/responses";
import { HttpClient, setParams } from "./request";
import { apiRoutes } from "./routes";
import { CreateInvoice, UpdateInvoice } from "src/models/requests";


export const listOfInvoices = async (params?: any): Promise<InvoiceListResponse> => {
    params = setParams(params);
    return await HttpClient.get<InvoiceListResponse>(`${apiRoutes.INVOICE_LIST}`, { ...params });
}

export const listOfInvoicesByClient = async (params?: any): Promise<InvoiceListResponse> => {
    params = setParams(params);
    return await HttpClient.get<InvoiceListResponse>(`${apiRoutes.INVOICE_LIST_CLIENT}`);
}

export const createInvoice = async (createInvoice: CreateInvoice): Promise<InvoiceResponse> => {
    return await HttpClient.post<InvoiceResponse>(`${apiRoutes.INVOICE_CREATE}`, createInvoice, { headers: { "Content-Type": "multipart/form-data" } });
}

export const updateInvoiceStatus = async (id: string, updateInvoiceStatus: UpdateInvoice): Promise<any> => {
    return await HttpClient.post<any>(`${apiRoutes.INVOICE_UPDATE}/${id}`, updateInvoiceStatus);
}

export const tagInvoice = async (orderId: string, id: string): Promise<any> => {
    return await HttpClient.post<any>(`${apiRoutes.INVOICE_TAG}/${id}/${orderId}`, {});
}

