export const apiRoutes = {
    ORDER_CREATE: 'order/new/',
    ORDER_CREATE_BY_ADMIN: 'order/new/admin',
    ORDER_UPDATE_STATUS: 'order/update',
    ORDER_LIST: 'order/all',
    ORDER_LIST_CLIENT: 'order/all/user',
    ORDER_ASSETS: 'order/cryptos',
    INVOICE_CREATE: 'order/invoice/create',
    INVOICE_UPDATE: 'order/invoice/update',
    INVOICE_LIST_CLIENT: 'order/invoice/user',
    INVOICE_LIST: 'order/invoice/all',
    INVOICE_TAG: 'order/invoice/tag',
    AUTH_SIGN_IN: 'user/login',
    AUTH_ADMIN_SIGN_IN: '/auth/login',
    AUTH_ADMIN_REFRESH_TOKEN: 'auth/reset-token',
    AUTH_VERIFY_CODE: 'user/verify',
    AUTH_REFRESH_TOKEN: 'user/refresh'
}