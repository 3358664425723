import { InvoiceStatus } from "src/models/invoice";

export const invoiceHead = ["Date Created", "Date updated", "invoice Number", "Vendor name", "Status", "Tagged", ""];
export const invoiceStatus = ['APPROVED', 'REJECTED'];
export const invoiceStatusFilter = ['PENDING', 'APPROVED', 'REJECTED', 'All'];
export const getStatusColor = (status: InvoiceStatus): any => {
    const mapping = {
        'PENDING': 'yellow',
        'APPROVED': 'green',
        'REJECTED': 'red',
        '': 'gray'
    }

    return mapping[status] || 'gray'
}