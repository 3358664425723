import React from 'react';
import { StepperHeaderProps } from 'src/models/stepper';

export const StepHeader: React.FC<StepperHeaderProps> = ({ position, current, title }) => {

    const mappingClassName = {
        'start': `arrow-stepper-start inline-block flex items-center justify-center relative text-white text-xs pl-4 w-32 h-16 ${current ? 'bg-dark after:text-dark' : 'bg-gray-500 after:text-gray-500'}  rounded-s-full mr-2  hover:after:text-dark hover:bg-dark hover:after:text-dark`,
        'middle': `arrow-stepper-center inline-block flex items-center justify-center relative text-white text-xs pl-10 w-32 h-16 ${current ? 'bg-dark after:text-dark' : 'bg-gray-500 after:text-gray-500'} before:text-white mr-2 hover:bg-dark hover:after:text-dark`,
        'end': `arrow-stepper-end inline-block flex items-center justify-center relative text-white text-xs pl-10 w-36 h-16  ${current ? 'bg-dark' : 'bg-gray-500'} before:text-white rounded-e-full shover:bg-dark`
    }
    return (
        <div className={mappingClassName[position]}>{title}</div>
    )
}