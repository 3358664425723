import React from 'react';
import { Sidebar } from './sidebar';
import { Outlet } from 'react-router-dom';
import {
    IconButton,
    Drawer
} from "@material-tailwind/react";
export default function PrivateRoute() {

    const [open, setOpen] = React.useState(false);
    const toogleDrawer = () => setOpen(!open);
    return (
        <div className='bg-light w-full flex flex-col lg:flex-row'>
            <div className="absolute top-0 left-0 lg:hidden">
                <IconButton variant="text" color="blue" className="rounded-full" onClick={toogleDrawer}>
                    <i className="icon icon-detail w-6 h-6 text-blue-500" />
                </IconButton>

            </div>
            <div className='w-[17%] hidden lg:flex'>
                <Sidebar />
            </div>
            <div className='lg:hidden'>
                <Drawer
                    placement="left"
                    className='w-[60%]'
                    open={open}
                    onClose={() => setOpen(false)}
                >
                    <Sidebar />
                </Drawer>
            </div>

            <div className='lg:px-12 px-4 py-6 lg:w-[83%] w-full flex flex-col'>
                <Outlet />
            </div>
        </div>
    )
}