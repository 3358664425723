import { useEffect, useState } from "react";
import { useLocalStorage } from "./storage.hook";
import { User } from "src/models/user";

export const useUser = () => {
    const { setObject, getObject } = useLocalStorage();
    const [user, setUser] = useState<User | null>(getObject('user'));

    useEffect(() => {
        setUser(getObject('user'));
    }, [])

    const addUser = (user: User) => {
        setUser(user);
        setObject("user", user);
    };

    return { user, addUser };
};