import React, { useEffect, useState } from 'react';
import { StepperProps } from 'src/models/stepper';
import { StepHeader } from './step-header';
import { StepperBody } from './step-body';
import { StepperFooter } from './step-footer';

export const Stepper: React.FC<StepperProps> = ({ data }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currenData, setCurrenData] = useState(data[0]);

    useEffect(() => {
        setCurrenData(data[currentIndex])
    }, [data, currentIndex])
    return (
        <div className='w-9/12 ml-8'>
            <div className="w-full py-4 px-8 flex items-center justify-center">
                {data.map((elt, index) => {
                    return (
                        <StepHeader index={index} key={index} position={index === 0 ? "start" : index === data.length - 1 ? 'end' : 'middle'} current={currentIndex === index} title={elt.title} />
                    );
                })}
            </div>
            <StepperBody index={currentIndex}>
                {currenData.children}
            </StepperBody>
            <StepperFooter index={currentIndex} position={currentIndex === 0 ? "start" : currentIndex === data.length - 1 ? 'end' : 'middle'} changeStateHandler={setCurrentIndex} active={currenData?.active} handler={currenData?.handler} isLoading={currenData?.isLoading} />
        </div>
    )
}