import { immediateToast } from "izitoast-react";

export const validateEmail = (email: string) => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return validRegex.test(email);
}

export const copyToclipBoard = (data: string | number) => {
    if (!data) { return }
    navigator.clipboard.writeText(data.toString());
    immediateToast("info", {
        message: 'Copied to clipboard',
        position: "topRight",
        timeout: 1000
    });
}

export const thousandSeparator = (str:any) => {
    return str.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}