import { Dialog, DialogHeader, DialogBody, DialogFooter, Button, Typography, Input, Alert, IconButton } from "@material-tailwind/react";
import { immediateToast } from "izitoast-react";
import React, { useState, useEffect } from "react";
import { createInvoice } from "src/api/invoice";
import { ButtonLoader } from "src/components/loaders/button-loader";
import { CreateInvoice } from "src/models/requests";

const defaultInvoice = {
    file: null,
    invoiceNumber: '',
    vendorName: ""
}
export const AddInvoiceDialog = ({ open, handleOpen }: any) => {

    const [invoice, setInvoice] = useState<CreateInvoice>({ ...defaultInvoice })
    const [error, setError] = useState('');


    useEffect(() => {
        setInvoice({ ...defaultInvoice })
        setError('');
    }, [open])

    const [isLoading, setLoading] = useState(false);

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        setInvoice({ ...invoice, [name]: value });
    };

    const addInvoice = async (event: any) => {
        try {
            setLoading(true);
            await createInvoice(invoice);
            immediateToast("success", {
                message: 'Invoice added successfully',
                position: "topRight"
            });
            handleOpen(event, true);
        } catch (exception: any) {
            const message = exception?.response?.data?.message || 'Something went wrong. Please try again';
            setError(message);
            immediateToast("error", {
                message,
                position: "topRight"
            });
        } finally {
            setLoading(false)
        }
    };
    const onFileChange = (event: any) => {
        setInvoice({ ...invoice, file: event.target.files[0] });

    };

    const upload = () => {
        document.getElementById('fileInput')?.click();

    };


    return (
        <Dialog
            open={open}
            handler={handleOpen}
            animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0.9, y: -100 },
            }}
        >
            <DialogHeader>Add invoice</DialogHeader>
            <DialogBody>
                <form>
                    <Typography variant="h6" color="blue-gray">
                        Invoice number
                    </Typography>
                    <Input
                        size="lg"
                        placeholder="37698949789"
                        value={invoice.invoiceNumber}
                        name="invoiceNumber"
                        onChange={handleChange}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }} crossOrigin={undefined} />
                    <Typography variant="h6" color="blue-gray" className="mt-3">
                        Vendor name
                    </Typography>
                    <Input
                        size="lg"
                        placeholder="John Doe"
                        value={invoice.vendorName}
                        name="vendorName"
                        onChange={handleChange}
                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                        labelProps={{
                            className: "before:content-none after:content-none",
                        }} crossOrigin={undefined} />

                    <div className="flex items-center justify-start mt-3">
                        <IconButton variant="outlined" id="file" onClick={upload} className="rounded-full">
                            <i className="icon icon-upload w-5 h-5" />
                        </IconButton>
                        <label htmlFor="file" className="ml-3" >{invoice?.file ? invoice.file.name : 'Upload invoice'}</label>
                        <input
                            type="file"
                            className="hidden"
                            id="fileInput"
                            onChange={onFileChange}
                        />
                    </div>

                </form>
                {error &&
                    <Alert variant="ghost" color="red" className="mt-2">
                        <span>{error}.</span>
                    </Alert>}

            </DialogBody>
            <DialogFooter className="flex justify-between">
                <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    <span>Cancel</span>
                </Button>
                <Button onClick={addInvoice} disabled={isLoading || !invoice?.file || !invoice?.invoiceNumber || !invoice?.vendorName}>{isLoading ? <ButtonLoader /> : 'Confirm'}
                </Button>
            </DialogFooter>
        </Dialog>

    )
}