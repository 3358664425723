import { OrderStatus } from "src/models/order";

export const orderHead = ["Date Created", "Fiat amount", "Crypto Amount", "Rate", "Email", "Status", "Name", ""];
export const orderStatus = ['APPROVED', 'REJECTED'];
export const orderStatusFilter = ['PENDING', 'APPROVED', 'REJECTED', 'All'];

export const getStatusColor = (status: OrderStatus): any => {
    const mapping = {
        'PENDING': 'yellow',
        'APPROVED': 'green',
        'REJECTED': 'red',
        '': 'gray'
    }

    return mapping[status] || 'gray'
}