import axios, { HttpStatusCode } from 'axios';
import { apiRoutes } from './routes';
import { useLocalStorage } from 'src/hooks/storage.hook';
import { Role, User } from 'src/models/user';
import { routes } from 'src/data/routes';

const requestIntstance = axios.create({
    baseURL: `${process.env.REACT_APP_API_URL}/api`,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    },
});

requestIntstance.interceptors.request.use((config) => {
    const { getObject } = useLocalStorage();
    const token = getObject('token');
    if (token) {
        config.headers.Authorization = `Bearer ${token.token}`;
    }


    return config;
});

requestIntstance.interceptors.response.use((response) => {
    return response
}, async function (error) {
    const originalRequest = error?.config;
    if (originalRequest && error?.response?.status === HttpStatusCode.Unauthorized && !originalRequest._retry) {
        RefreshToken(originalRequest);
    }
    return Promise.reject(error);
});


const RefreshToken = async (originalRequest: any) => {
    originalRequest._retry = true;
    const { getObject, setObject } = useLocalStorage();
    const user = getObject('user') as User;
    try {
        const dataToken = getObject('token');
        const { refreshToken, token } = dataToken;
        if (user.role === Role.CLIENT) {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/${apiRoutes.AUTH_REFRESH_TOKEN}`, {}, { headers: { ['refresh-token']: refreshToken, Authorization: `Bearer ${token}` } });
            const tokenData = response.data.data;
            setObject('token', tokenData);
            originalRequest.headers.Authorization = `Bearer ${tokenData.token}`;
        }

        if (user.role === Role.ADMIN) {
            const response = await adminRequestInstance.post(`/${apiRoutes.AUTH_ADMIN_REFRESH_TOKEN}/`, { refreshToken });
            const tokenData = response.data.data;
            setObject('token', { token: tokenData.token, refreshToken });
            originalRequest.headers.Authorization = `Bearer ${tokenData.token}`;
        }
        return await requestIntstance(originalRequest);
    } catch (error) {
        logout(user?.role || Role.CLIENT);
        return Promise.reject(error);
    }
}



const adminRequestInstance = axios.create({
    baseURL: `${process.env.REACT_APP_ADMIN_AUTH_URL}/api/v1`,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'client-id': process.env.REACT_APP_CLIENT_ID,
        "api-key": process.env.REACT_APP_CLIENT_SECRET
    },
});

export class HttpClient {
    static async get<T>(url: string, params?: unknown) {
        const response = await requestIntstance.get<T>(url, { params });
        return response.data;
    }

    static async post<T>(url: string, data: unknown, options?: any) {
        const response = await requestIntstance.post<T>(url, data, options);
        return response.data;
    }

    static async put<T>(url: string, data: unknown, options?: any) {
        const response = await requestIntstance.put<T>(url, data, options);
        return response.data;
    }

    static async adminPost<T>(url: string, data: unknown, options?: any) {
        const response = await adminRequestInstance.post<T>(url, data, options);
        return response.data;
    }

}

export const setParams = (params: any) => {
    for (const key in params) {
        if (!params[key]) { delete params[key] }
    }

    return params;
}


const logout = (role: Role) => {
    localStorage.clear();
    window.location.href = role === Role.ADMIN ? routes.LOGIN_ADMIN : routes.LOGIN;

}



