import { VerifyLoginResponse } from "../models/responses";
import { HttpClient } from "./request";
import { apiRoutes } from "./routes";


export const signIn = async (email: string): Promise<any> => {
    return await HttpClient.post<any>(`${apiRoutes.AUTH_SIGN_IN}`, { email });
}

export const adminSignIn = async (log: string, password?: string): Promise<any> => {
    return await HttpClient.adminPost<any>(`${apiRoutes.AUTH_ADMIN_SIGN_IN}/`, { log, password });
}

export const verifyCode = async (code: string, email: string): Promise<VerifyLoginResponse> => {
    return await HttpClient.post<VerifyLoginResponse>(`${apiRoutes.AUTH_VERIFY_CODE}`, { email, code });
}
