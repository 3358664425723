import React, { useEffect, useState } from "react";
import { Chip, IconButton, Input, Select, Typography, Option, Button, Menu, MenuHandler, MenuList, MenuItem, Tooltip } from "@material-tailwind/react";
import { getStatusColor, orderHead, orderStatusFilter } from "src/data/history";
import { SetOrderStatusDialog } from "src/dialogs/setOrderStatusDialog";
import { DetailOrderDialog } from "src/dialogs/detailDialog";
import { listOfOrdersByClient, listOfOrders, getAssets } from "src/api/order";
import { useUser } from "src/hooks/user.hook";
import { OrderResponse } from "src/models/responses";
import { CustomLoader } from "src/components/loaders/custom-loader";
import { Empty } from "src/components/empty";
import { ErrorComponent } from "src/components/error";
import { Role } from "src/models/user";
import { immediateToast } from "izitoast-react";
import moment from "moment";
import { CryptoData, OrderFilter } from "src/models/order";
import { ButtonLoader } from "src/components/loaders/button-loader";
import { useLocalStorage } from "src/hooks/storage.hook";
import { TagInvoiceDialog } from "src/dialogs/tagInvoiceDialog";

export default function History() {
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [detailDialogOpen, setDetailDialogOpen] = useState(false);
  const [tagDialogOpen, setTagDialogOpen] = useState(false);

  const [selectedOrder, setSelectedOrder] = useState<OrderResponse | null>(null);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [isEmpty, setEmpty] = useState(false);


  const [filter, setFilter] = useState<OrderFilter>({
    email: '',
    crypto: '',
    status: '',
  });

  const [orders, setOrders] = useState<OrderResponse[]>([]);

  const { user } = useUser();
  const { getObject, setObject } = useLocalStorage();

  const [cryptosFilter, setCryptosFilter] = useState<CryptoData[]>([]);

  useEffect(() => {
    setEmpty(false)
    getOrders();
    fetchData();
  }, []);

  const fetchData = async () => {
    let assets = getObject('assets');
    if (!assets) {
      const result = await getAssets();
      assets = result?.data;
      if (result instanceof Error || !result) { assets = { networks: [] } }
      setObject("assets", assets);
    }
    setCryptosFilter([...assets?.networks, { code: '', name: 'All', icon: '' }]);
  };

  const handleSelect = (event: any, name: string) => {
    setFilter({ ...filter, [name]: event });
  };
  const getOrders = () => {
    setLoading(true)
    const getFunc = user?.role === Role.CLIENT ? listOfOrdersByClient : listOfOrders;
    getFunc(filter).then((result) => {
      setOrders(result.data);
      if (result.data.length === 0) { setEmpty(true) }
    })
      .catch(() => {
        immediateToast("error", {
          message: 'Something went wrong. Please try again',
          position: "topRight"
        });
        setError(true)
      })
      .finally(() => setLoading(false));
  };
  const handleStatusOpen = (index: number, updated?: boolean) => {
    setSelectedOrder(orders[index]);
    setStatusDialogOpen(!statusDialogOpen)
    if (updated) { getOrders() }
  };
  const handleDetailOpen = (index: number) => {
    setSelectedOrder(orders[index]);
    setDetailDialogOpen(true);
  };


  const handleTagOpen = (index: number) => {
    setSelectedOrder(orders[index]);
    setTagDialogOpen(true);
  };

  const menu = [
    { label: 'Details', handle: handleDetailOpen, icon: 'icon-detail' },
    { label: 'Update status', handle: handleStatusOpen, icon: 'icon-edit', pendingActivate: true }
  ];

  return (<>
    <div className="w-full">
      <Typography variant="h3" color="black" className="font-bold mt-3 mb-4 text-md lg:text-xl">
        Transaction History
      </Typography>
      {user?.role === Role.ADMIN &&
        <div className="flex flex-col lg:flex-row my-4 justify-between">
          <div className="flex w-full lg:w-3/4 flex-wrap  justify-between lg:justify-start">
            <div className="flex flex-col items-start w-[48%] lg:w-1/4 mt-3">
              <Select variant="outlined" label="Filter By Status " className="w-full" value={filter.status} onChange={(value) => handleSelect(value, 'status')}>
                {orderStatusFilter.map((elt: any, index) => <Option className="bg-green" key={index} value={elt === 'All' ? '' : elt}  >{elt}</Option>)}
              </Select>

            </div>
            <div className="flex flex-col lg:ml-2 items-start mt-3 w-[48%] lg:w-1/4 ">
              <Select variant="outlined" label="Filter By Crypto" name="crypto"
                selected={(element) =>
                  element &&
                  React.cloneElement(element, {
                    disabled: true,
                    className:
                      "flex items-center opacity-100 px-0 gap-2 pointer-events-none",
                  })} onChange={(value) => handleSelect(value, 'crypto')}>
                {cryptosFilter.map(({ icon, name, code }) => <Option className="bg-green flex items-center gap-2" key={code} value={code}>
                  {icon && <img
                    src={icon}
                    alt={name}
                    className="h-5 w-5 rounded-full object-cover"
                  />}  {name}</Option>)}
              </Select>

            </div>
            <div className="flex flex-col lg:ml-2 items-start mt-3 w-[48%] lg:w-1/4 ">
              <Input
                type="email"
                placeholder="Filter By Email"
                value={filter.email}
                name="email"
                onChange={(value) => handleSelect(value.target.value, 'email')}
                className=" !border-t-blue-gray-200 focus:!border-t-gray-900"
                labelProps={{
                  className: "before:content-none after:content-none",
                }} crossOrigin={undefined} />

            </div>
            <div className="lg:hidden lg:w-1/4 flex-col lg:ml-2 items-end lg:justify-end  mt-3 w-[48%] lg:w-1/4  mt-3 flex">
              <Button size="md" variant="filled" disabled={isLoading} onClick={getOrders} className="w-full" > {isLoading ? <ButtonLoader /> : 'Filter'}</Button>
            </div>
          </div>
          <div className="lg:flex lg:w-1/4 lg:justify-end mt-3 hidden">
            <Button size="md" variant="filled" disabled={isLoading} onClick={getOrders} > {isLoading ? <ButtonLoader /> : 'Filter'}</Button>
          </div>
        </div>
      }
      <div className="w-full h-[calc(100vh_-_10rem)] overflow-y-scroll">
        {isLoading ? <CustomLoader /> : isError ? <ErrorComponent /> : isEmpty ? <Empty /> :
          <table className="table-auto text-left w-full">
            <thead>
              <tr className="hover:bg-gray-300">
                {orderHead.map((head) => (
                  <th
                    key={head}
                    className="p-2 border-b border-blue-gray-100"
                  >
                    <Typography
                      variant="small"
                      className="font-normal text-dark  font-semibold text-xs break-normal"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {orders.map(({ name, status,
                fiatAmount, rate, cryptoAmount, dateCreated, email, asset }, index) => {
                const isLast = index === orders.length - 1;
                const classes = isLast ? "p-2" : "p-2 border-b border-blue-gray-100";
                const valueClasses = "font-normal black text-xs"

                return (
                  <tr key={index} className="hover:bg-gray-300">
                    <td className={classes}>
                      <Typography
                        variant="small"
                        className={valueClasses}
                      >
                        {moment(dateCreated).format('DD/MM/YYYY HH:mm:ss') || '-'}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        className={valueClasses}
                      >
                        {fiatAmount && `${fiatAmount}` || '-'}&nbsp;{fiatAmount && `XAF`}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        className={`${valueClasses} text-[11px] lg:text-xs text-no-wrap`}
                      >
                        {cryptoAmount && `${cryptoAmount}` || '-'}&nbsp;{`${asset?.stablecoin?.name}`}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        className={valueClasses}
                      >
                        {rate || '-'}
                      </Typography>
                    </td>
                    <td className={classes}>

                      <Typography
                        variant="small"
                        className={valueClasses}
                      >
                        {email}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Chip variant="ghost" className="text-center" color={getStatusColor(status)} value={status || ''} />
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        className={valueClasses}
                      >
                        {name}
                      </Typography>
                    </td>

                    {user?.role === Role.ADMIN ?
                      <td className={classes}>
                        <Menu
                          animate={{
                            mount: { y: 0 },
                            unmount: { y: 25 },
                          }}
                        >
                          <MenuHandler>
                            <IconButton variant="text" color="blue">
                              <i className="icon icon-detail w-6 h-6 text-blue-500" />
                            </IconButton>
                          </MenuHandler>
                          <MenuList>
                            {menu.map((elt, i) =>
                              !(elt.pendingActivate && status !== 'PENDING') &&
                              <MenuItem key={i} className="flex items-center justify-start" onClick={() => elt.handle(index)}>
                                <i className={`icon ${elt.icon} w-6 h-6 text-blue-500 mr-2`} />
                                {elt.label}
                              </MenuItem>
                            )}
                          </MenuList>
                        </Menu>
                        <Tooltip content="Tag Invoice">
                          <IconButton variant="text" color="blue" onClick={() => handleTagOpen(index)}>
                            <i className="icon icon-invoice w-6 h-6 text-blue-500" />
                          </IconButton>
                        </Tooltip>


                      </td>

                      : <td className={classes}>
                        <IconButton variant="text" color="blue" onClick={() => handleDetailOpen(index)}>
                          <i className="icon icon-detail w-6 h-6 text-blue-500" />
                        </IconButton>
                      </td>
                    }

                  </tr>
                );
              })}
            </tbody>
          </table>
        }
      </div>
    </div>
    <SetOrderStatusDialog handleOpen={handleStatusOpen} open={statusDialogOpen} id={selectedOrder?.id} network={selectedOrder?.asset?.stablecoin?.code} />
    <DetailOrderDialog handleOpen={() => setDetailDialogOpen(!detailDialogOpen)} open={detailDialogOpen} order={selectedOrder} />
    <TagInvoiceDialog handleOpen={() => setTagDialogOpen(!tagDialogOpen)} open={tagDialogOpen} id={selectedOrder?.id} />

  </>
  );
}