import { Dialog, DialogHeader, DialogBody, DialogFooter, Button, Typography, Chip, IconButton, Tooltip } from "@material-tailwind/react";
import moment from "moment";
import React from "react";
import { getStatusColor } from "src/data/history";
import { copyToclipBoard } from "src/utils";

export const DetailOrderDialog = ({ open, handleOpen, order }: any) => {
    const className = "flex justify-between items-center pb-2 lg:pt-4 border-b border-gray-300 flex-col lg:flex-row"
    return (
        <Dialog
            open={open}
            handler={handleOpen}
            animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0.9, y: -100 },
            }}
        >
            <DialogHeader className="text-md lg:text-xl ">Order Details</DialogHeader>
            <DialogBody>
                <div className="lg:px-16 overflow-auto">
                    <div className={className}>
                        <div className="flex lg:flex-col lg:items-start justify-between  items-center lg:w-1/2 w-full">
                            <Typography
                                variant="h6"
                                color="blue-gray"
                                className="font-medium text-xs" >
                                Date Created
                            </Typography>

                            <Typography
                                variant="h5"
                                color="blue-gray"
                                className="lg:text-md text-sm font-medium"
                            >
                                {moment(order?.dateCreated).format('DD/MM/YYYY HH:mm:ss') || '-'}
                            </Typography>
                        </div>
                        <div className="flex lg:flex-col lg:items-start justify-between  items-center  lg:w-1/2 w-full">
                            <Typography
                                variant="h6"
                                color="blue-gray"
                                className="font-medium text-xs"
                            >
                                Status
                            </Typography>
                            <Chip variant="ghost" className="text-center lg:text-md text-xs" color={getStatusColor(order?.status)} value={order?.status || ''} />
                        </div>
                    </div>
                    <div className={className}>
                        <div className="flex lg:flex-col lg:items-start justify-between  items-center lg:w-1/2 w-full">
                            <Typography
                                variant="h6"
                                color="blue-gray"
                                className="font-medium text-xs"
                            >
                                Fiat amount
                            </Typography>

                            <Typography
                                variant="h5"
                                color="blue-gray"
                                className="lg:text-md text-sm font-medium"
                            >
                                {order?.fiatAmount && `${order?.fiatAmount} XAF` || '-'}
                            </Typography>
                        </div>
                        <div className="flex lg:flex-col lg:items-start justify-between  items-center lg:w-1/2 w-full">
                            <Typography
                                variant="h6"
                                color="blue-gray"
                                className="font-medium text-xs"
                            >
                                Crypto amount
                            </Typography>

                            <Typography
                                variant="h5"
                                color="blue-gray"
                                className="lg:text-md text-sm font-medium"
                            >
                                {order?.cryptoAmount && `${order?.cryptoAmount}` || '-'}&nbsp;{order?.asset?.stablecoin?.name}
                            </Typography>
                        </div>
                    </div>

                    <div className={className}>
                        <div className="flex lg:flex-col lg:items-start justify-between  items-center lg:w-1/2 w-full">
                            <Typography
                                variant="h6"
                                color="blue-gray"
                                className="font-medium text-xs"
                            >
                                Crypto
                            </Typography>

                            <Typography
                                variant="h5"
                                color="blue-gray"
                                className="lg:text-md text-sm font-medium"
                            >
                                {order?.crypto || '-'}
                            </Typography>
                        </div>
                        <div className="flex lg:flex-col lg:items-start justify-between  items-center lg:w-1/2 w-full">
                            <Typography
                                variant="h6"
                                color="blue-gray"
                                className="font-medium text-xs"
                            >
                                Crypto Network
                            </Typography>

                            <Typography
                                variant="h5"
                                color="blue-gray"
                                className="lg:text-md text-sm font-medium"
                            >
                                {order?.cryptoNetwork || '-'}
                            </Typography>
                        </div>
                    </div>

                    <div className={className}>
                        <div className="flex lg:flex-col lg:items-start justify-between  items-center lg:w-1/2 w-full">
                            <Typography
                                variant="h6"
                                color="blue-gray"
                                className="font-medium text-xs"
                            >
                                Rate
                            </Typography>

                            <Typography
                                variant="h5"
                                color="blue-gray"
                                className="lg:text-md text-sm font-medium"
                            >
                                {order?.rate && `${order?.rate}` || '-'}
                            </Typography>
                        </div>
                        <div className="flex lg:flex-col lg:items-start justify-between   items-center lg:w-1/2 w-full">
                            <Typography
                                variant="h6"
                                color="blue-gray"
                                className="font-medium text-xs"
                            >
                                Crypto Address
                            </Typography>
                            <Tooltip content={order?.cryptoAddress}>
                                <div className="flex items-center lg:justify-start justify-end w-1/2 lg:w-full">
                                    <Typography
                                        as="h6"
                                        variant="small"
                                        color="blue-gray"
                                        className="font-medium lg:text-md text-sm  truncate lg:w-3/4 w-1/2 "
                                    >
                                        {order?.cryptoAddress || '-'}
                                    </Typography>
                                    <IconButton variant="text" color="blue" className="w-1/4" onClick={() => copyToclipBoard(order?.cryptoAddress)}>
                                        <i className="icon icon-copy w-4 h-4 text-blue-500" />
                                    </IconButton>
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                    <div className={className}>
                        <div className="flex lg:flex-col lg:items-start justify-between  items-center lg:w-1/2 w-full">
                            <Typography
                                variant="h4"
                                color="blue-gray"
                                className="font-medium text-xs"
                            >
                                Bank Name
                            </Typography>

                            <Typography
                                variant="h5"
                                color="blue-gray"
                                className="lg:text-md text-sm font-medium"
                            >
                                {order?.bankName || '-'}
                            </Typography>
                        </div>

                        <div className="flex lg:flex-col lg:items-start justify-between  items-center lg:w-1/2 w-full">
                            <Typography
                                variant="h4"
                                color="blue-gray"
                                className="font-medium text-xs"
                            >
                                Bank Number
                            </Typography>

                            <Typography
                                variant="h5"
                                color="blue-gray"
                                className="lg:text-md text-sm  font-medium"
                            >
                                {order?.bankNumber || '-'}
                            </Typography>

                        </div>
                    </div>
                    <div className={className}>
                        <div className="flex lg:flex-col lg:items-start justify-between  items-center lg:w-1/2 w-full">
                            <Typography
                                variant="h6"
                                color="blue-gray"
                                className="font-medium text-xs"
                            >
                                Name
                            </Typography>

                            <Typography
                                variant="h5"
                                color="blue-gray"
                                className="text-md  font-medium"
                            >
                                {order?.name || '-'}
                            </Typography>
                        </div>
                        <div className="flex lg:flex-col lg:items-start justify-between  items-center lg:w-1/2 w-full">
                            <Typography
                                variant="h6"
                                color="blue-gray"
                                className="font-medium text-xs"
                            >
                                Address
                            </Typography>

                            <Typography
                                variant="h5"
                                color="blue-gray"
                                className="lg:text-md text-sm   font-medium"
                            >
                                {order?.address || '-'}
                            </Typography>

                        </div>

                    </div>
                    <div className={className}>
                        <div className="flex lg:flex-col lg:items-start justify-between  items-center lg:w-1/2 w-full">
                            <Typography
                                variant="h6"
                                color="blue-gray"
                                className="font-medium text-xs"
                            >
                                Company name
                            </Typography>

                            <Typography
                                variant="h5"
                                color="blue-gray"
                                className="text-md  font-medium"
                            >
                                {order?.companyName || '-'}
                            </Typography>
                        </div>
                        <div className="flex lg:flex-col lg:items-start justify-between  items-center lg:w-1/2 w-full">
                            <Typography
                                variant="h6"
                                color="blue-gray"
                                className="font-medium text-xs"
                            >
                                Creator
                            </Typography>

                            <Typography
                                variant="h5"
                                color="blue-gray"
                                className="lg:text-md text-sm   font-medium"
                            >
                                {order?.creator || '-'}
                            </Typography>

                        </div>

                    </div>
                    <div className={className}>
                        <div className="flex lg:flex-col lg:items-start justify-between  items-center lg:w-1/2 w-full">
                            <Typography
                                variant="h4"
                                color="blue-gray"
                                className="font-medium text-xs"
                            >
                                Phone number
                            </Typography>

                            <Typography
                                variant="h5"
                                color="blue-gray"
                                className="lg:text-md text-sm  font-medium"
                            >
                                {order?.phoneNumber || '-'}
                            </Typography>

                        </div>
                        <div className="flex lg:flex-col lg:items-start justify-between  items-center lg:w-1/2 w-full">
                            <Typography
                                variant="h4"
                                color="blue-gray"
                                className="font-medium text-xs text-no-wrap"
                            >
                                Transaction link
                            </Typography>
                            {order?.rate &&
                                <Tooltip content={order?.transactionLink}>
                                    <div className="flex items-center justify-end w-1/2 lg:w-full">
                                        <Typography
                                            variant="h5"
                                            as="a"
                                            target="_blank"
                                            href={order?.transactionLink}
                                            color="blue-gray"
                                            className="lg:text-md text-sm  truncate w-3/4 lg:w-11/12 hover:text-dark text-blue-500 font-medium"
                                        >
                                            {order?.transactionLink || '-'}
                                        </Typography>
                                        <IconButton variant="text" color="blue" onClick={() => copyToclipBoard(order?.transactionLink)}>
                                            <i className="icon icon-copy w-3 h-3  lg:w-4 lg:h-4 text-blue-500" />
                                        </IconButton>

                                    </div>
                                </Tooltip>
                            }
                        </div>
                    </div>
                </div>
            </DialogBody>
            <DialogFooter>
                <Button
                    variant="text"
                    color="gray"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    <span>Close</span>
                </Button>
            </DialogFooter>
        </Dialog>
    )

}