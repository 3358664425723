import { CreateOrder, UpdateOrderStatus } from "src/models/requests";
import { AssetsResponse, OrderListResponse, OrderResponse } from "../models/responses";
import { HttpClient, setParams } from "./request";
import { apiRoutes } from "./routes";


export const listOfOrders = async (params: any): Promise<OrderListResponse> => {
    params = setParams(params);
    return await HttpClient.get<OrderListResponse>(`${apiRoutes.ORDER_LIST}`, { ...params });
}

export const listOfOrdersByClient = async (params: any): Promise<OrderListResponse> => {
    params = setParams(params);
    return await HttpClient.get<OrderListResponse>(`${apiRoutes.ORDER_LIST_CLIENT}`);
}

export const getAssets = async (): Promise<AssetsResponse> => {
    return await HttpClient.get<AssetsResponse>(`${apiRoutes.ORDER_ASSETS}`);
}

export const createOrder = async (createOrder: CreateOrder): Promise<OrderResponse> => {
    return await HttpClient.post<OrderResponse>(`${apiRoutes.ORDER_CREATE}`, createOrder);
}
export const createOrderByAdmin = async (createOrder: CreateOrder): Promise<OrderResponse> => {
    return await HttpClient.post<OrderResponse>(`${apiRoutes.ORDER_CREATE_BY_ADMIN}`, createOrder);
}

export const updateOrderStatus = async (id: string, updateOrderStatus: UpdateOrderStatus): Promise<any> => {
    return await HttpClient.post<any>(`${apiRoutes.ORDER_UPDATE_STATUS}/${id}`, updateOrderStatus);
}