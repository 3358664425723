import React, { useState } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Input,
    Button,
    Typography,
    Alert,
} from "@material-tailwind/react";
import { useAuth } from "src/hooks/auth.hook";
import { ButtonLoader } from "src/components/loaders/button-loader";

export default function LoginAdmin() {
    const [username, setUsername] = useState('');
    const [error, setError] = useState('');
    const [password, setPassWord] = useState('');
    const [isLoading, setLoading] = useState(false);
    const { adminLogin } = useAuth();


    const singin = async () => {
        try {
            setLoading(true);
            await adminLogin(username, password);
        } catch (exception: any) {
            setError(exception?.message || 'Something went wrong. Please try again');
        } finally {
            setLoading(false)
        }
    }


    return (
        <Card className="w-full max-w-[30rem] mx-2">
            <CardHeader
                color="red"
                floated={false}
                shadow={false}
                className="m-0 grid place-items-center  bg-dark px-4 py-8 text-center"
            >
                <img src="/logo.svg" alt="Logo Ejara" />
                <Typography variant="h5" className="mt-3" color="white">
                    Simple OTC by Ejara
                </Typography>
            </CardHeader>
            <CardBody>
                <Typography
                    variant="h3"
                    color="blue-gray"
                    className="mb-2 font-medium text-center"
                >
                    Admin Login
                </Typography>
                <div className="mt-6 flex flex-col gap-4">
                    <div>
                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="mb-2 font-medium"
                        >
                            Your username
                        </Typography>
                        <Input
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900 mb-2"
                            disabled={isLoading}
                            value={username}
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }} crossOrigin={undefined}
                            onKeyDown={(event) => event.key === 'Enter' && password && username ? singin() : null}
                            onChange={(event) => setUsername(event.target.value)}
                        />

                        <Typography
                            variant="small"
                            color="blue-gray"
                            className="mt-6 mb-2 font-medium"
                        >
                            Your Password
                        </Typography>
                        <Input
                            type="password"
                            className=" !border-t-blue-gray-200 focus:!border-t-gray-900 mb-2"
                            disabled={isLoading}
                            value={password}
                            labelProps={{
                                className: "before:content-none after:content-none",
                            }} crossOrigin={undefined}

                            onChange={(event) => setPassWord(event.target.value)}
                            onKeyDown={(event) => event.key === 'Enter' && password && username ? singin() : null}
                        />
                        {error && <Alert variant="ghost" color="red" className="mt-2">
                            <span>{error}.</span>
                        </Alert>}
                    </div>
                    <Button size="lg" className="bg-dark" onClick={singin} disabled={isLoading || !username || !password}>{isLoading ? <ButtonLoader /> : 'Continue'}</Button>
                </div>
            </CardBody>
        </Card>
    );
}