import React, { createContext, useEffect, useState } from 'react';
import { BaseProps } from 'src/models/props';
import { useLocalStorage } from "../hooks/storage.hook";

export const AuthContext = createContext({
  isAuthenticated: false,
  setIsAuthenticated: () => { }
});

export const AuthProvider: React.FC<BaseProps> = ({ children }) => {
  const { getObject } = useLocalStorage();
  const [isAuthenticated, setIsAuthenticated] = useState(!!getObject('token'));

  useEffect(() => {
    checkIsAuthenticated()
  }, []);

  const checkIsAuthenticated = () => {
    const token = getObject('token');
    setIsAuthenticated(!!token);
  }

  return <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated: checkIsAuthenticated }}>{children}</AuthContext.Provider>;
};

