import secureLocalStorage from "react-secure-storage";

export const useLocalStorage = () => {

    const setString = (key: string, value: string) => {
        secureLocalStorage.setItem(key, value);
    };

    const getString = (key: string) => {
        const value = secureLocalStorage.getItem(key);
        return value;
    };

    const setObject = (key: string, value: Object) => {
        secureLocalStorage.setItem(key, JSON.stringify(value));
    };

    const getObject = (key: string) => {
        const jsonValue = secureLocalStorage.getItem(key);
        return !!jsonValue ? JSON.parse(jsonValue.toString()) : null;
    };

    const removeItem = (key: string) => {
        secureLocalStorage.removeItem(key);
    };

    const clearStorage = () => {
        secureLocalStorage.clear();
    };

    return { setString, getString, setObject, getObject, removeItem, clearStorage };
};