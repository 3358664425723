export type User = {
    email?: string,
    name?: string,
    role?: Role,
    isActive?: boolean
}

export enum Role {
    ADMIN = 'ADMIN',
    CLIENT = 'CLIENT'
};