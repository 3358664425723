import { Dialog, DialogHeader, DialogBody, DialogFooter, Button, Typography, Select, Option, Alert } from "@material-tailwind/react";
import { immediateToast } from "izitoast-react";
import React, { useState, useEffect } from "react";
import { updateInvoiceStatus } from "src/api/invoice";
import { ButtonLoader } from "src/components/loaders/button-loader";
import { invoiceStatus } from "src/data/invoice";
import { UpdateInvoice } from "src/models/requests";

export const SetInvoiceStatusDialog = ({ open, handleOpen, invoiceId }: any) => {

    const [invoice, setInvoice] = useState<UpdateInvoice>({
        status: 'PENDING',
    })
    const [error, setError] = useState('');

    useEffect(() => {
        setInvoice({
            status: 'PENDING',
        })
        setError('');
    }, [open])

    const [isLoading, setLoading] = useState(false);

    const handleSelect = (event: any) => {
        setInvoice({ ...invoice, status: event });
    };



    const updateInvoice = async () => {
        try {
            setLoading(true);
            await updateInvoiceStatus(invoiceId, invoice);
            immediateToast("success", {
                message: 'Invoice status updated successfully',
                position: "topRight"
            });
            handleOpen(0, true);
        } catch (exception: any) {
            const message = exception?.response?.data?.message || 'Something went wrong. Please try again';
            setError(message);
            immediateToast("error", {
                message,
                position: "topRight"
            });
        } finally {
            setLoading(false)
        }
    };

    return (
        <Dialog
            open={open}
            handler={handleOpen}
            animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0.9, y: -100 },
            }}
        >
            <DialogHeader>Invoice Status</DialogHeader>
            <DialogBody>
                <form>
                    <Typography
                        variant="small"
                        color="blue-gray"
                        className="mb-1 mt-4 font-medium"
                    >
                        Status
                    </Typography>
                    <Select variant="outlined" label="Select Status" onChange={handleSelect}>
                        {invoiceStatus.map((elt) => <Option className="bg-green" value={elt} key={elt} >{elt}</Option>)}
                    </Select>
                </form>
                {error &&
                    <Alert variant="ghost" color="red" className="mt-2">
                        <span>{error}.</span>
                    </Alert>}

            </DialogBody>
            <DialogFooter className="flex justify-between">
                <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    <span>Cancel</span>
                </Button>
                <Button variant="gradient" color="green" onClick={updateInvoice} disabled={isLoading}>{isLoading ? <ButtonLoader /> : 'Confirm'}
                </Button>
            </DialogFooter>
        </Dialog>
    )
}