import React, { Suspense } from 'react';
import Router from './router';
import { ThemeProvider } from "@material-tailwind/react";
import { customTheme } from './data/theme';
import { AuthProvider } from './context/authContext';
import { PageLoader } from './components/loaders/page-loader';
export default function App() {

    return (
        <Suspense fallback={<PageLoader />}>
            <AuthProvider>
                <ThemeProvider value={customTheme}>
                    <Router />
                </ThemeProvider>
            </AuthProvider>
        </Suspense>
    );

}