import React, { useEffect, useState } from "react";
import { Chip, IconButton, Typography, Button, Select, Option } from "@material-tailwind/react";
import { listOfInvoicesByClient, listOfInvoices } from "src/api/invoice";
import { useUser } from "src/hooks/user.hook";
import { CustomLoader } from "src/components/loaders/custom-loader";
import { Empty } from "src/components/empty";
import { ErrorComponent } from "src/components/error";
import { Role } from "src/models/user";
import { immediateToast } from "izitoast-react";
import moment from "moment";
import { getStatusColor, invoiceHead, invoiceStatusFilter } from "src/data/invoice";
import { Invoice } from "src/models/invoice";
import { AddInvoiceDialog } from "src/dialogs/addInvoiceDialog";
import { SetInvoiceStatusDialog } from "src/dialogs/setInvoiceStatusDialog";
import { DoneIcon, ExclamationIcon } from "src/components/icons";

export default function InvoiceHistory() {
  const [addDialogOpen, setAddDialogOpen] = useState(false);

  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);
  const [isEmpty, setEmpty] = useState(false);

  const [invoices, setInvoices] = useState<Invoice[]>([]);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [selectedInvoice, setSelectedInvoice] = useState<Invoice | null>(null);


  const { user } = useUser();


  const [filter, setFilter] = useState<any>({
    status: '',
  });


  useEffect(() => {
    setEmpty(false)
    getInvoices();
  }, []);


  const getInvoices = () => {
    fetchInvoices(filter)
  };
  const fetchInvoices = (filter: any) => {
    setLoading(true)
    const getFunc = user?.role === Role.CLIENT ? listOfInvoicesByClient : listOfInvoices;
    getFunc(filter).then((result) => {
      setInvoices(result.data);
      if (result.data.length === 0) { setEmpty(true) }
    })
      .catch(() => {
        immediateToast("error", {
          message: 'Something went wrong. Please try again',
          position: "topRight"
        });
        setError(true)
      })
      .finally(() => setLoading(false));
  };
  const handleDownload = (url: string, vendorName: string, invoiceNumber: string) => {
    const link = document.createElement('a');
    link.download = `${invoiceNumber}-${vendorName}`;
    link.href = url;
    link.target = "_blank"
    link.click();
  };


  const handleAddInvoice = (event: any, updated?: boolean) => {
    setAddDialogOpen(!addDialogOpen)
    if (updated) {
      fetchInvoices({});
    }
  };
  const handleSelect = (event: any, name: string) => {
    setFilter({ ...filter, [name]: event });
    fetchInvoices({ [name]: event });
  };


  const handleStatusOpen = (index: number, updated?: boolean) => {
    setSelectedInvoice(invoices[index]);
    setStatusDialogOpen(!statusDialogOpen)
    if (updated) { getInvoices() }
  };

  return (<>
    <div className="w-full">
      <Typography variant="h3" color="black" className="font-bold mt-3 mb-4 text-md lg:text-xl">
        Invoices
      </Typography>
      <div className="flex my-4 justify-between">
        <div className="flex flex-col items-start w-[48%] lg:w-1/4 mt-3">
          {user?.role === Role.ADMIN &&
            <Select variant="outlined" label="Filter By Status " className="w-full" value={filter.status} onChange={(value) => handleSelect(value, 'status')}>
              {invoiceStatusFilter.map((elt: any, index) => <Option className="bg-green" key={index} value={elt === 'All' ? '' : elt}  >{elt}</Option>)}
            </Select>
          }
        </div>
        <div className="lg:w-1/4 flex lg:ml-2 lg:justify-end  mt-3  lg:w-1/4  mt-3 flex">
          {user?.role === Role.CLIENT &&
            <Button size="md"  disabled={isLoading}  onClick={() => setAddDialogOpen(!addDialogOpen)}>Pay Invoice</Button>
          }
        </div>
      </div>
      <div className="w-full h-[calc(100vh_-_10rem)] overflow-y-scroll">
        {isLoading ? <CustomLoader /> : isError ? <ErrorComponent /> : isEmpty ? <Empty /> :
          <table className="table-auto text-left w-full">
            <thead>
              <tr className="hover:bg-gray-300">
                {invoiceHead.map((head) => (
                  <th
                    key={head}
                    className="p-2 border-b border-blue-gray-100"
                  >
                    <Typography
                      variant="small"
                      className="font-normal text-dark  font-semibold text-xs break-normal"
                    >
                      {head}
                    </Typography>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {invoices.map(({ vendorName, status,
                invoiceNumber, invoiceUrl, dateCreated, dateUpdated, orderId }, index) => {
                const isLast = index === invoices.length - 1;
                const classes = isLast ? "p-2" : "p-2";
                const valueClasses = "font-normal black text-xs"

                return (
                  <tr key={index} className="hover:bg-gray-300 border-b border-blue-gray-100">
                    <td className={classes}>
                      <Typography
                        variant="small"
                        className={valueClasses}
                      >
                        {moment(dateCreated).format('DD/MM/YYYY HH:mm:ss') || '-'}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        variant="small"
                        className={valueClasses}
                      >
                        {moment(dateUpdated).format('DD/MM/YYYY HH:mm:ss') || '-'}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Typography
                        className={valueClasses}
                      >
                        {invoiceNumber || '-'}
                      </Typography>
                    </td>
                    <td className={classes}>

                      <Typography
                        variant="small"
                        className={valueClasses}
                      >
                        {vendorName || '-'}
                      </Typography>
                    </td>
                    <td className={classes}>
                      <Chip variant="ghost" className="text-center" color={getStatusColor(status)} value={status || ''} />
                    </td>
                    <td className={`${classes}`}>
                      {orderId ? <DoneIcon className='w-6 h-6 text-blue-500 ml-4' /> : <ExclamationIcon className='w-6 h-6 text-gray-500 ml-4' />}
                    </td>


                    <td className={classes}>
                      <IconButton variant="text" color="blue" onClick={() => handleDownload(invoiceUrl, vendorName, invoiceNumber)}>
                        <i className="icon icon-download w-6 h-6 text-blue-500" />
                      </IconButton>
                      {(status !== "PENDING" || user?.role === Role.CLIENT) ? '' :
                        <IconButton variant="text" color="blue" onClick={() => handleStatusOpen(index)}>
                          <i className="icon icon-edit w-6 h-6 text-blue-500" />
                        </IconButton>
                      }

                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        }
      </div>
    </div>
    <AddInvoiceDialog handleOpen={handleAddInvoice} open={addDialogOpen} />
    <SetInvoiceStatusDialog handleOpen={handleStatusOpen} open={statusDialogOpen} invoiceId={selectedInvoice?.id} />
  </>
  );
}