import { useUser } from "./user.hook";
import { useLocalStorage } from "./storage.hook";
import { Role } from "src/models/user";
import { signIn, adminSignIn, verifyCode } from "src/api/auth"
import { VerifyLoginResponse } from "src/models/responses";
import { useNavigate } from "react-router-dom";
import { routes } from "src/data/routes";
import { useContext } from "react";
import { AuthContext } from "src/context/authContext";
import { immediateToast } from "izitoast-react";

export const useAuth = () => {
  const { user, addUser } = useUser();
  const { setObject, clearStorage } = useLocalStorage();
  const navigate = useNavigate();
  const { setIsAuthenticated } = useContext(AuthContext);


  const login = async (email: string): Promise<any> => {
    let result: any;
    result = await signIn(email);
    if (result instanceof Error) { return result }
    const user = { email, role: Role.CLIENT };
    addUser(user);
    return result;
  };

  const adminLogin = async (username: string, password: string): Promise<any> => {
    let result: any;
    result = await adminSignIn(username, password);
    if (result instanceof Error) { return result }
    const { token, refresh_token } = result;
    const user = { email: username, role: Role.ADMIN };
    addUser(user);
    setObject("token", { token, refreshToken: refresh_token })
    setIsAuthenticated();
    immediateToast("success", {
      message: 'Logged in successfully',
      position: "topRight"
    });
    navigate(routes.ORDER_HISTORY);
    return result;
  };

  const verifyOtp = async (code: string, email: string): Promise<VerifyLoginResponse> => {
    let result: VerifyLoginResponse;
    result = await verifyCode(code, email);
    if (result instanceof Error) { return result }
    setObject("token", result.data);
    setIsAuthenticated();
    immediateToast("success", {
      message: 'Logged in successfully',
      position: "topRight"
    });
    navigate(routes.ORDER_HISTORY);
    return result;
  };

  const logout = () => {
    clearStorage();
    setIsAuthenticated();
    immediateToast("success", {
      message: 'Logged out successfully',
      position: "topRight"
    });
    navigate(user?.role === Role.ADMIN ? routes.LOGIN_ADMIN : routes.LOGIN);
  };

  return { user, login, adminLogin, logout, verifyOtp };
};
