import React, { useState } from "react";
import {
    Card,
    CardHeader,
    CardBody,
    Input,
    Button,
    Typography,
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
    Alert,
} from "@material-tailwind/react";
import { useAuth } from "src/hooks/auth.hook";
import { ButtonLoader } from "src/components/loaders/button-loader";
import { validateEmail } from "src/utils"

export default function Login() {
    const [type, setType] = useState("login");
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [otpError, setOtpError] = useState('');
    const [otp, setOtp] = useState('');
    const [isLoading, setLoading] = useState(false);
    const { login, verifyOtp } = useAuth();

    const singin = async () => {
        try {
            setLoading(true);
            await login(email);
            setType('otp');
        } catch (exception: any) {
            setEmailError(exception?.message || 'Something went wrong. Please try again');
        } finally {
            setLoading(false)
        }
    }

    const verifyCode = async () => {
        try {
            setLoading(true);
            await verifyOtp(otp, email);
        } catch (exception: any) {
            setOtpError(exception?.response?.data?.message || 'Something went wrong. Please try again');
        } finally {
            setLoading(false)
        }
    }

    return (
        <Card className="w-full max-w-[30rem]  mx-2">
            <CardHeader
                color="red"
                floated={false}
                shadow={false}
                className="m-0 grid place-items-center  bg-dark px-4 py-8 text-center"
            >
                <img src="/logo.svg" alt="Logo Ejara" />
                <Typography variant="h5" className="mt-3" color="white">
                    Simple OTC by Ejara
                </Typography>
            </CardHeader>
            <CardBody>
                <Tabs value={type} key={type}>
                    <TabsHeader className="relative z-0 ">
                        <Tab value="login">
                            Login
                        </Tab>
                        <Tab value="otp" disabled={type === 'login'} onClick={() => setType('otp')}>
                            Otp Code
                        </Tab>
                    </TabsHeader>
                    <TabsBody
                        animate={{
                            initial: {
                                x: type === "login" ? 400 : -400,
                            },
                            mount: {
                                x: 0,
                            },
                            unmount: {
                                x: type === "login" ? 400 : -400,
                            },
                        }}
                    >
                        <TabPanel value="login" className="p-0">
                            <div className="mt-6 flex flex-col gap-4">
                                <div>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="mb-2 font-medium"
                                    >
                                        Your Email
                                    </Typography>
                                    <Input
                                        type="email"
                                        placeholder="name@mail.com"
                                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900 mb-2"
                                        disabled={isLoading}
                                        value={email}
                                        labelProps={{
                                            className: "before:content-none after:content-none",
                                        }} crossOrigin={undefined}

                                        color={validateEmail(email) ? 'green' : 'red'}
                                        onChange={(event) => setEmail(event.target.value)}
                                        onKeyDown={(event) => event.key === 'Enter' && validateEmail(email) ? singin() : null}
                                    />
                                    {emailError && <Alert variant="ghost" color="red" className="mt-2">
                                        <span>{emailError}.</span>
                                    </Alert>}
                                </div>
                                <Button size="lg" className="bg-dark" onClick={singin} disabled={isLoading || !email || !validateEmail(email)}>{isLoading ? <ButtonLoader /> : 'Continue'}</Button>
                            </div>
                        </TabPanel>
                        <TabPanel value="otp" className="p-0">
                            <div className="mt-6 flex flex-col gap-4">
                                <div>
                                    <Typography
                                        variant="small"
                                        color="blue-gray"
                                        className="mb-2 font-medium"
                                    >
                                        Enter the code you received by email
                                    </Typography>
                                    <Input
                                        type="text"
                                        placeholder="597945"
                                        value={otp}
                                        onChange={(event) => setOtp(event.target.value)}
                                        className=" !border-t-blue-gray-200 focus:!border-t-gray-900 mb-2"
                                        labelProps={{
                                            className: "before:content-none after:content-none",
                                        }} crossOrigin={undefined}
                                        onKeyDown={(event) => event.key === 'Enter' && otp ? verifyCode() : null}
                                    />
                                    {otpError && <Alert variant="ghost" color="red" className="mt-2">
                                        <span>{otpError}.</span>
                                    </Alert>}
                                </div>
                                <Button size="lg" className="bg-dark" onClick={verifyCode} disabled={isLoading || !otp}>{isLoading ? <ButtonLoader /> : 'Confirm'}</Button>
                                <Button size="lg" variant="text" onClick={() => setType('login')}>Back</Button>
                            </div>
                        </TabPanel>
                    </TabsBody>
                </Tabs>
            </CardBody>
        </Card>
    );
}