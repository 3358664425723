import { Menu } from "src/models/menu";
import { routes } from "./routes";

export const menu: Menu[] = [
    {
        title: "Buy Crypto",
        path: routes.ORDER_NEW,
        icon: 'add',
    },
    {
        title: "Pay Invoice",
        path: routes.INVOICE_HISTORY,
        icon: 'invoice',
    },
    {
        title: "Orders History",
        path: routes.ORDER_HISTORY,
        icon: 'history',
    }
  
]