import {
    Typography,
    List,
    ListItem,
    ListItemPrefix,
} from "@material-tailwind/react";
import { menu } from '../data/menu';
import { useAuth } from "src/hooks/auth.hook";
import { useLocation } from 'react-router-dom'
import { useUser } from "src/hooks/user.hook";


export function Sidebar() {

    const { logout } = useAuth();
    const { user } = useUser();
    const location = useLocation();
    const currPath = location.pathname;
    return (
        <div className="bg-dark w-full max-w-[20rem] p-2 2xl:p-4 shadow-xl h-screen">
            <div className="mb-12 px-4">
                <img src="/logo.svg" alt="Logo Ejara" />
            </div>
            <List className="mb-36 min-w-[200px]">
                {menu.map((elt, index) => {
                    return (
                        <a href={elt.path} key={index}>
                            <ListItem color="gray" selected={currPath === elt.path} className="w-full 2xl:w-full  2xl:p-3 xl:w-40  xl:p-2 lg:w-3/5 p-2">
                                <ListItemPrefix>
                                    <img className="h-5 w-5 2xl:h-7 2xl:w-7 xl:w-6 xl:h-6" src={`/icons/${elt.icon}.svg`} alt={elt.icon} />
                                </ListItemPrefix>
                                <Typography variant="h6" color="white" className="font-light text-base lg:text-xs 2xl:text-base xl:text-sm">
                                    {elt.title}
                                </Typography>
                            </ListItem>
                        </a>
                    )
                })}
            </List>
            <div className="border w-full border border-gray-500"></div>
            <div className="p-4">
                <div className="rounded-full w-12 h-12 2xl:w-16 2xl:h-16 border border-blue-100 shadow-xl  ring-4 text-white flex items-center justify-center text-3xl">
                    {user?.email?.charAt(0).toUpperCase() || 'E'}
                </div>
                <Typography variant="h6" color="white" className="font-light mt-3 text-xs 2xl:text-lg">
                    {user?.email || 'Ejara'}
                </Typography>
                <Typography variant="h6" className="font-light mt-1 text-gray-500 cursor-pointer text-xs 2xl:text-lg" onClick={logout}>
                    Logout
                </Typography>
            </div>
        </div>
    );
}
