import React, { useContext } from "react";
import {
    createBrowserRouter,
    Navigate,
    RouterProvider,
} from "react-router-dom";
import History from "./pages/history";
import Order from "./pages/order";
import Login from "./pages/login";
import { AuthContext } from "./context/authContext";
import PrivateRoute from "./containers/privateRoute";
import PublicRoute from "./containers/publicRoute";
import { routes } from "src/data/routes"
import LoginAdmin from "./pages/login-admin";
import InvoiceHistory from "./pages/invoice";


export default function Router() {
    const { isAuthenticated } = useContext(AuthContext);

    const router = createBrowserRouter([
        {
            path: '/',
            element: <PrivateRoute />,
            children: [
                {
                    path: '/',
                    element: <Navigate to={routes.ORDER_HISTORY} />
                },
                {
                    path: routes.ORDER_HISTORY,
                    element: <History />,
                },
                {
                    path: routes.ORDER_NEW,
                    element: <Order />,
                },
                {
                    path: routes.INVOICE_HISTORY,
                    element: <InvoiceHistory />,
                },
            ]
        },
        {
            path: routes.LOGIN,
            element: isAuthenticated ? <Navigate to={routes.ORDER_HISTORY} /> : <PublicRoute><Login /></PublicRoute>,
        },
        {
            path: routes.LOGIN_ADMIN,
            element: isAuthenticated ? <Navigate to={routes.ORDER_HISTORY} /> : <PublicRoute><LoginAdmin /></PublicRoute>,
        }

    ]);
    return (
        <RouterProvider router={router} />
    );
}