import { Button } from '@material-tailwind/react';
import React from 'react';
import { StepperFooterProps } from 'src/models/stepper';
import { ButtonLoader } from '../loaders/button-loader';

export const StepperFooter: React.FC<StepperFooterProps> = ({ index, position, active, handler, changeStateHandler, isLoading }) => {
    const changeStep = () => {
        if (handler) {
            handler();
        }
        if (position === 'end') { return }
        changeStateHandler(index + 1)
    }
    return (
        <div className='w-full mt-8 flex justify-between flex-row-reverse items-center'>
            <Button className='bg-dark ml-2 text-center flex justify-center' variant="filled" onClick={changeStep} disabled={!active || isLoading}>{isLoading ? <ButtonLoader /> : position === 'end' ? 'Confirm' : 'Next'}</Button>
            <Button variant="outlined" className={`border-dark text-center flex justify-center ${position === 'start' ? 'hidden' : ''}`} onClick={() => changeStateHandler(index - 1)}>Previous</Button>
        </div>
    );
}   