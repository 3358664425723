import { Dialog, DialogHeader, DialogBody, DialogFooter, Button, Typography, Input,Card } from "@material-tailwind/react";
import { immediateToast } from "izitoast-react";
import React, { useState, useEffect } from "react";
import { listOfInvoices, tagInvoice } from "src/api/invoice";
import { ButtonLoader } from "src/components/loaders/button-loader";
import Autocomplete from "react-autocomplete"
import { Invoice } from "src/models/invoice";
import { CustomLoader } from "src/components/loaders/custom-loader";
import { ErrorComponent } from "src/components/error";

export const TagInvoiceDialog = ({ open, handleOpen, id }: any) => {
    const [isLoading, setLoading] = useState(false);
    const [isConfirmLoading, setConfirmLoading] = useState(false);
    const [isError, setError] = useState(false);
    const [value, setValue] = useState<any>('');
    const [invoices, setInvoices] = useState<Invoice[]>([]);
    const [items, setItems] = useState<string[]>([]);
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        setValue("");
        getInvoices();
    }, [open]);

    useEffect(() => {
        setItems(getItem(''));
    }, [invoices]);


    const getInvoices = async () => {
        if (open) {
            try {
                setLoading(true)
                const result = await listOfInvoices({});
                setInvoices(result.data);
            } catch (error) {
                immediateToast("error", {
                    message: 'Something went wrong. Please try again',
                    position: "topRight"
                });
                setError(true)

            } finally {
                setLoading(false)
            }
        }
    };


    const updateInvoice = async () => {
        try {
            setConfirmLoading(true);
            const invoiceId = invoices.find((elt) => elt.invoiceNumber === value)?.id.toString()

            if (!invoiceId) {
                return immediateToast("warning", {
                    message: 'Select invoice number',
                    position: "topRight"
                });
            }
            await tagInvoice(id, invoiceId);
            immediateToast("success", {
                message: 'Order status updated successfully',
                position: "topRight"
            });
            handleOpen(0, true);
        } catch (exception: any) {
            const message = exception?.response?.data?.message || 'Something went wrong. Please try again';
            immediateToast("error", {
                message,
                position: "topRight"
            });
        } finally {
            setConfirmLoading(false)
        }
    };

    const filterValue = (value: string) => {
        return items.filter((elt) => elt.includes(value))
    };

    const getItem = (value?: string) => {
        return value ? filterValue(value) : invoices.map((elt) => elt.invoiceNumber);
    };
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const element = event.target.value || '';
        setValue(element);
        setItems(getItem(element))
    };




    return (
        <Dialog
            open={open}
            handler={handleOpen}
            animate={{
                mount: { scale: 1, y: 0 },
                unmount: { scale: 0.9, y: -100 },
            }}
            size="xs"
        >
            <DialogHeader className="w-full flex justify-center" >Tag invoice</DialogHeader>
            <DialogBody>
                {isLoading ? <CustomLoader /> : isError ? <ErrorComponent /> :

                    <div className="h-[15vh] flex flex-col items-center" >
                        <form>
                            <Typography
                                variant="small"
                                color="blue-gray"
                                className="mb-1 mt-4 font-medium"
                                onClick={() => setMenuOpen(false)}

                            >
                                Invoice number
                            </Typography>

                            <Autocomplete
                                getItemValue={(item) => item}
                                renderInput={(props: any) => <Input
                                    className="!border !border-gray-300 bg-white text-gray-900 shadow-lg shadow-gray-900/5 ring-4 ring-transparent placeholder:text-gray-500 focus:!border-gray-900 focus:!border-t-gray-900 focus:ring-gray-900/10"
                                    labelProps={{
                                        className: "hidden",
                                    }}
                                    value={props.value}
                                    crossOrigin={undefined}
                                    ref={props.ref}
                                    onChange={props.onChange}
                                    onClick={() => setMenuOpen(!menuOpen)}
                                    onDrag={props.onDrag}
                                    onBeforeInput={props.onBeforeInput}
                                    onFocus={props.onFocus}
                                    onKeyDown={props.onKeyDown}
                                    onKeyUp={props.onKeyUp}
                                    onKeyDownCapture={props.onKeyDownCapture}
                                    onSelect={props.onSelect}

                                />}
                                open={menuOpen}
                                items={items}
                                renderItem={(item) =>
                                    <div key={item} className="p-2 hover:bg-gray-300 cursor-pointer bg-white">{item}</div>
                                }
                                renderMenu={(items) => <Card>
                                    <div children={items} className="overflow-y-scroll  scroll-smooth h-48" />
                                </Card>}
                                value={value}
                                onChange={handleChange}
                                onSelect={(val) => {                                   
                                    setValue(val);
                                    setMenuOpen(false)
                                }}
                            />
                        </form>
                    </div>
                }
            </DialogBody>
            <DialogFooter className="flex justify-between">
                <Button
                    variant="text"
                    color="red"
                    onClick={handleOpen}
                    className="mr-1"
                >
                    <span>Cancel</span>
                </Button>
                <Button variant="gradient" className="static" color="green" disabled={isLoading || isConfirmLoading || !value} onClick={updateInvoice} >{isConfirmLoading ? <ButtonLoader /> : 'Confirm'}
                </Button>
            </DialogFooter>
        </Dialog>
    )
}